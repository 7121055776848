
/* Document level adjustments */
html {
    font-size: 16px;
}
@media (max-width: 768px) {
    html { font-size: 15px; }
}
@media (max-width: 375px) {
    html { font-size: 13px; }
}
